import React from "react";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";

// const StyledStaticData = styled.td`
//   background: white;
//   &:first-of-type {
//     min-width: 20ch;
//   }
// `;
const StyledStaticData = styled.td`
  background: black;
 

 &:nth-of-type(1) {
    min-width: 2ch; /* Styles for the first occurrence */
  }

  &:nth-of-type(2) {
    /* Styles for the second occurrence */
    min-width: 55ch;
    min-height: 0ch;
    /* Set the height */
  /* Set the width */
  }
  &:nth-of-type(3) {
    /* Styles for the second occurrence */
    min-width: 30ch;
   
  }
  &:nth-of-type(4) {
    /* Styles for the second occurrence */
    min-width: 30ch;
    
  }
`;
const StyledStaticTableRow = styled.tr`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #3e1eb3 solid 1px;
`;

export const StaticTableRow = ({ row }) => {
  return (
    <StyledStaticTableRow {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <StyledStaticData {...cell.getCellProps()}>
              <DragHandle isDragging />
              <span>{cell.render("Cell")}</span>
            </StyledStaticData>
          );
        }
        return (
          <StyledStaticData style={{ width: '0px', height: '0px' }} {...cell.getCellProps()}>
            {cell.render("Cell")}
          </StyledStaticData>
        );
      })}
    </StyledStaticTableRow>
  );
};
