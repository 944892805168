import React,{useState} from 'react'

import { useMutation, useQuery, useQueryClient } from "react-query";
import * as querydata from "../../services/service";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
const DeleteAlbumModalComponent = ({
    rowData = null,
    rowNameData = '',
    show='',
    handleClose='',
}) => {

    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const handleDeleteAlbum = (albumId) => {
      
        deleteUserRecord.mutate(albumId);
        handleClose();
    };
    const deleteUserRecord = useMutation(querydata.deleteAlbumData, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            queryClient.invalidateQueries('getMediaAlbumFolData')
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete - {rowNameData}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Once you delete this Album, all the files in this album will also be deleted irrevocably. Are
                        you sure you want to go ahead?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button  disabled={isLoading} className='btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2' variant="primary" onClick={() => handleDeleteAlbum(rowData)}>
                      
                    
                    {isLoading ? (
                            <div
                              className="spinner-border text-primary btn-theme fw-semibold"
                              role="status"
                            ></div>
                          ) : (
                            "Delete"
                          )}
                          </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteAlbumModalComponent
