import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as querydata from "../../services/service";

import {
  getAllUsers,
  getAllUserRoles,
  websiteList,
  insertAssignedUserRole,
} from "../dataService/DataService";
import MultiDropDown from "./MultiDropDown";

export const userSchema = Yup.object({
  userId: Yup.string().required("Please select user name"),
});
function ManageAssignedUserRoles() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedUserId, setUserId] = useState();
  const [dropdownCount, setDropdownsCount] = useState(null);
  const [selectedWebsiteArray, setSelectedWebsiteArray] = useState([]); //not confirm
  const [editable, setEditable] = useState(true);

  let AllUsers = useQuery("getAllUsers", getAllUsers);
  let AllUsersRoles = useQuery("getAllUserRoles", getAllUserRoles);
  let AllWebsiteList = useQuery("websiteList", websiteList);
  const [allUserRoleData, setAllUserRoleData] = useState([]);
  const assignedUserRolesDataByUserId = useQuery(
    ["getUserAssignedByUserId", selectedUserId],
    () => querydata.getUserAssignedByUserId(selectedUserId)
  );
  const assignedRoles =
    (assignedUserRolesDataByUserId &&
      assignedUserRolesDataByUserId.data &&
      assignedUserRolesDataByUserId.data.data &&
      assignedUserRolesDataByUserId.data.data) ||
    [];

  const [formData, setFormData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedUserId) {
          // Fetch data for website dropdown
          await AllWebsiteList.refetch();

          // Fetch data for user role dropdown
          await AllUsersRoles.refetch();

          // Fetch data for the selected user
          const allUserroleDatas =
            await assignedUserRolesDataByUserId.refetch();
          if (allUserroleDatas) {
            setAllUserRoleData(allUserroleDatas);
          } else {
            toast.error("Failed to fetch user role data");
          }
        }
      } catch (error) {
        // Handle error
        toast.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedUserId]);

  useEffect(() => {
    if (assignedRoles) {
      assignedRoles.map((uRdata, index) => {
        const newDropdownKey = index.toString();
        setDropdowns((prevDropdowns) => [
          ...prevDropdowns,
          {
            key: newDropdownKey,
            options: filteredareadata,
            onDelete: () => handleRemoveDropdown(newDropdownKey),
          },
        ]);

        setFormData((prevFormData) => ({
          ...prevFormData,
          [newDropdownKey]: {
            website_id: uRdata.website_id,
            user_role_id: uRdata.user_role_id,
          },
        }));
      });

      setDropdownsCount(assignedRoles.length);
    }
  }, [assignedRoles]);

  const initialValues = { formData };

  useEffect(() => {
    setValues({ ...initialValues });
  }, [allUserRoleData]);

  const { values, setValues, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: userSchema,

      onSubmit: (values, action) => {
        // checking validation
        const newArray = Object.values(initialValues.formData);

        for (let item of newArray) {
          if (!item.website_id || !item.user_role_id) {
            toast.dismiss();
            toast.error("please make a valid selection ");
            return;
          }
        }

        const FormDataNewArray = Object.values(initialValues);
        const userId = selectedUserId;
        if(!addnewprojectMutation.isLoading){

          addnewprojectMutation.mutate({ FormDataNewArray, userId });
        }
        // action.resetForm();
      },
    });
  //form post

  const addnewprojectMutation = useMutation(insertAssignedUserRole, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-user-role");
      queryClient.invalidateQueries("getUserAssignedByUserId");
      setDropdowns([]);
      setUserId("");
      setFormData("");
      setAllUserRoleData([]);
      setValues({});
      setDropdownsCount(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleOnChangeUser = async (e) => {
    const selectedUserId = e.target.value;
    setFormData({});

    setDropdowns([]);
    setUserId(selectedUserId);
  };

  const handleAddDropdown = (index) => {
    const newDropdownKey = index.toString();
    setDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: filteredareadata,
        onSelectionChange: (value) => value,
        onDelete: () => handleRemoveDropdown(newDropdownKey),
      },
    ]);
    setDropdownsCount((prev) => prev + 1);
  };

  const handleRemoveDropdown = (index) => {
    const parentDiv = document.querySelector(
      `.row.position-relative[data-key="${index}"]`
    );

    if (parentDiv) {
      while (parentDiv.firstChild) {
        parentDiv.removeChild(parentDiv.firstChild);
      }
    }
    const updatedFormData = { ...formData };
    delete updatedFormData[index];

    // Update the state with the modified formData
    setFormData(updatedFormData);
    setDropdownsCount((prev) => prev - 1);
  };

  const commonHandleChange = (e, index, dropdownType) => {
    const { value } = e.target;

    // Assuming formData is an array and you want to update the specific index
    const updatedFormData = { ...formData };

    // Make sure there is an entry for the current index in formData
    updatedFormData[index] = updatedFormData[index] || {};

    // Update the appropriate dropdown value based on dropdownType
    updatedFormData[index][dropdownType] = value;
    // Set the updated formData state
    setFormData(updatedFormData);
  };

  const selectedArray = Object.entries(formData).map(([index, values]) => {
    const { website_id, user_role_id } = values;

    // Find the corresponding website details based on website_id
    const selectedWebsite = AllWebsiteList?.data?.data?.find(
      (data) => +data.website_id === +website_id
    );

    return {
      index: parseInt(index),
      website_id: parseInt(website_id),
      user_role_id,
      website: selectedWebsite?.website, // Add website details to the array
      ...values,
    };
  });

  const filteredareadata = AllWebsiteList?.data?.data?.filter(
    (data) =>
      !selectedArray.find(
        (selected) => +selected.website_id === +data.website_id
      )
  );

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Assign User Role</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Assign User Role</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-4">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="Users">
                                  Select User
                                </label>
                                <select
                                  name="UsersId"
                                  id="UsersId"
                                  className="form-control form-select py-3 shadow-none px-4"
                                  onChange={handleOnChangeUser}
                                  value={selectedUserId || ""}
                                >
                                  <option value="">---Select---</option>
                                  {AllUsers &&
                                    AllUsers.data &&
                                    AllUsers.data.data.map((option, index) => (
                                      <option
                                        key={option.user_id}
                                        value={option.user_id}
                                      >
                                        {option.user_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dropdowns.map((dropdown, index) => (
                          <>
                            <MultiDropDown
                              key={index}
                              index={index}
                              dropdown={dropdown}
                              handleRemoveDropdown={() =>
                                handleRemoveDropdown(index)
                              }
                              commonHandleChange={commonHandleChange}
                              error={error}
                              errors={errors}
                              touched={touched}
                              selectedUserId={selectedUserId}
                              assignedRoles={assignedRoles}
                              formData1={formData}
                              handleBlur={handleBlur}
                              values={values}
                              filteredareadata={filteredareadata}
                              selectedWebsites={selectedArray}
                            />
                          </>
                        ))}
                        {AllWebsiteList?.data?.data?.length > dropdownCount ? (
                          <div className="row position">
                            <div className="col-lg-4">
                              <div className="mb-4">
                                <a
                                  onClick={handleAddDropdown}
                                  disabled={!editable}
                                  className="btn btn-standard "
                                  style={{
                                    marginTop: "2.5rem",
                                    border: "1px solid #000",
                                  }}
                                >
                                  <i className="fa fa-plus"></i>ADD WEBSITE
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-12 text-end mt-4">
                          <input
                            onClick={handleSubmit}
                            disabled={
                              selectedUserId === undefined ||
                              selectedUserId === "" ||
                              Object.values(formData).some(
                                (data) =>
                                  data.user_role_id === undefined ||
                                  data.user_role_id === ""
                              )
                            }
                            type="submit"
                            value="Save"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAssignedUserRoles;
