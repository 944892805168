import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    closestCorners,
} from "@dnd-kit/core";
import {
    arrayMove,
    sortableKeyboardCoordinates,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortablePageDropDown from "./SortablePageDropDown";

const DropDownForEditPage = ({
    selectedDropdown,
    pageSubSectionDetails,
    handleSelect,
    handleShowModal,
    handleShowEditSubSectionModal,
    handleReorderSubSections,
    changeSortingIndexMutation,
    open,
    setOpen
}) => {

    const [tasks, setTasks] = useState(pageSubSectionDetails?.data?.data || []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getTaskPos = (id) => {
        return pageSubSectionDetails?.data?.data?.findIndex((item) => item.page_sub_section_id === parseInt(id));
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setTasks((items) => {
                const originalPos = getTaskPos(active.id);
                const newPos = getTaskPos(over.id);
                handleReorderSubSections(originalPos, newPos);
                changeSortingIndexMutation.mutate({
                    id: active.id,
                    newSortingIndex: newPos + 1,
                });
                return arrayMove(items, originalPos, newPos);
            });
        }
    };

    const items = pageSubSectionDetails?.data?.data?.map(item => ({ id: item.page_sub_section_id }));

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <Dropdown show={open}>
                    <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className="form-select form-control text-start sub-brand-select bg-white py-3"
                        onClick={() => setOpen(true)}
                    >
                        {selectedDropdown}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {pageSubSectionDetails?.data?.data?.map((subsection) => (
                            <SortablePageDropDown
                                key={subsection.page_sub_section_id}
                                id={subsection.page_sub_section_id}
                                subsection={subsection}
                                handleSelect={handleSelect}
                                handleShowEditSubSectionModal={handleShowEditSubSectionModal}
                                handleShowModal={handleShowModal}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </SortableContext>
        </DndContext>
    );
};

export default DropDownForEditPage;
