import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as querydata from "../../services/service";
import { useDropzone } from "react-dropzone";
import videoImg from "../../assets/images/video.png";
import pdfIcon from "../../assets/images/pdf-doc.svg";
function AddAlbumModal({ show, handleClose, headerText, albumId }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { websiteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [albumFile, setImages] = useState([]);
  const [formData, setFormData] = useState({
    imgTitle: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (albumFile.length === 0) {
      newErrors.img = "Please Select at least one image";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const formdata = new FormData();
        if (Array.isArray(albumFile)) {
          albumFile.forEach((fileData, index) => {
            
            formdata.append("albumFile", fileData.file);
          });
        } else {
        }

        addnewprojectMutation.mutate({ formdata, albumId });
        // Reset the form
        setImages("");
        setFormData("");
      } catch (error) {
        toast.dismiss();
        toast.error(`An error occurred: ${error.message}`);
      }
    }
  };

  const addnewprojectMutation = useMutation(querydata.insertAlbumFile, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      handleClose();
      queryClient.invalidateQueries("getAlbumFileData");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleAlbumFileChange = (e) => {
  
    const selectedFiles = e.target.files;
    const allowedFormats = [
      "jpg",
      "jpeg",
      "png",
      "mp4",
      "mov",
      "gif",
      "svg",
      "pdf",
    ];

    const maxFileSizeInBytes = 60*1024*1024;
    // const maxFileSizeInBytes = 60*1024*1024;
    const newImages = [];
    for (const selectedFile of selectedFiles) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
      if (!allowedFormats.includes(fileExtension)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          file:
            "Invalid file format. Allowed formats: " +
            allowedFormats.join(", "),
        }));
      } else if (fileSize > maxFileSizeInBytes) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          file: "File size exceeds the limit of 60 MB.",
        }));
      } else {
        newImages.push({
          file: selectedFile,
          previewURL: URL.createObjectURL(selectedFile),
        });
      }
    }

    setImages(newImages);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleChangeImgTitle = (event) => {
    setFormData({
      ...formData,
      imgTitle: event.target.value,
    });
  };
  const closeMediaModal = () => {
    setImages("");
    setFormData("");
    handleClose(false);
    setErrors({});
  };

  const { getRootProps, getInputProps } = useDropzone({

    // accept: ["image/*", "video/*", "application/pdf"],
    accept: {
      "image/jpeg": [".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp"],
      "image/png": [".png", ".apng", ".avif", ".svg", ".webp"],
      "application/pdf": [".pdf"],
      "video/mp4": [".mp4", ".m4v"],
      "video/quicktime": [".mov"],
    },
    onDrop: (acceptedFiles) => {
      
      // Handle the dropped files
      handleAlbumFileChange({
        target: {
          files: acceptedFiles,
        },
      });
    },
  });

  return (
    <Modal size="xl" show={show} onHide={closeMediaModal}>
      <Modal.Header className="modal-header px-5 py-4" closeButton>
        <Modal.Title className="modal-title fs-5">
          {headerText || "Add Album"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
          <div className="upload-file-wrapper">
            {addnewprojectMutation.isLoading ? (
              <div className="loader-wrapper">
                <div className="loader text-center">
                  <h6 className="mb-4">Optimizing your Media</h6>
                  <div className="spinner-border text-info"></div>
                </div>
              </div>
            ) : (
              <form>
                <div className="row">
                  {!albumFile.length ? (
                    <div className="col-12 mb-4">
                      <div className="col-12 mb-4">
                        <div
                          {...getRootProps()}
                          className="upload-label position-relative w-100 rounded-3 px-4 py-4"
                        >
                          <div className="mb-4">
                            <input {...getInputProps()} />
                          </div>
                          <div className="upload-file text-center">
                            {/* ... (existing code) */}
                            <div className="upload-icon m-auto rounded-1 d-flex align-items-center justify-content-center">
                              <i className="fa fa-plus"></i>
                            </div>
                            <div className="drag-drop w-100 mt-3">
                              <p className="m-0">
                                Click to select a file or drag & Drop a file in
                                this area
                              </p>
                            </div>
                            {errors.img && (
                              <span style={{ color: "red" }} className="text">
                                {errors.img}
                              </span>
                            )}
                            {errors.file && (
                              <p style={{ color: "red" }}>{errors.file}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="upload upload-media-album">
                        <div className="d-flex flex-wrap gap-4 col-12 media-contains">
                          {albumFile &&
                            albumFile.map((imageData, index) => (
                              <div
                                key={index}
                                className="upload-icon rounded-1 d-flex align-items-center justify-content-center"
                                style={{
                                  height: "201px",
                                  width: "200px",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <div>
                                  <a
                                    href={imageData.previewURL}
                                    target="_blank"
                                  >
                                    {imageData.file.name.includes(".pdf") ? (
                                      <img
                                        src={pdfIcon}
                                        style={{
                                          width: "200%",
                                          height: "200%",
                                          objectFit: "cover",
                                        }}
                                        alt="PDF Icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {imageData.file.name.includes(".mp4") ? (
                                      <img
                                        src={videoImg}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                        alt="Video Icon"
                                      />
                                    ) : (
                                      ""
                                    )}

                                    {!imageData.file.name.includes(".pdf") &&
                                    !imageData.file.name.includes(".mp4") ? (
                                      <img
                                        src={imageData.previewURL}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          backgroundColor: "grey",
                                        }}
                                        alt="Image Icon"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {/* <img
                                      src={imageData.previewURL}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    /> */}
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <Button
                                      className="remove-image-button text-danger p-0"
                                      style={{
                                        height: "33px",
                                        margin: "1px 0px 0px 0px",
                                        backgroundColor: "cornsilk",
                                      }}
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      <i className="fa fa-times text-danger"></i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        {errors.file && (
                          <p style={{ color: "red" }}>{errors.file}</p>
                        )}
                      </div>
                    </>
                  )}
                  {/* <!-- /col-12 --> */}
                </div>
                {/* <!-- /col-12 --> */}
                <div className="col-12 pt-5 text-end mb-2 d-flex justify-content-end">
                  <Button
                    onClick={closeMediaModal}
                    data-bs-dismiss="modal"
                    value="Cancel"
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-2 me-2"
                  >
                    Cancel
                  </Button>
                  {addnewprojectMutation.isLoading ? (
                    <button
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-5 py-2 upload-loader"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <input
                      onClick={handleSubmit}
                      type="submit"
                      value="upload"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                    />
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddAlbumModal;
