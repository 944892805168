import React from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";

const amenitySchema = Yup.object({
  amenityName: Yup.string().required("Please enter amenity Name"),
});

function AmenityModal({
  show,
  handleClose,
  handleSubmit,
  values,
  amenityFileData,
  showCropMediaModal,
  handleChange,
  handleBlur,
  errors,
  touched,
  modalTitle,
  modalHeading,
  amenityCropImage,
  aminityLoading,
}) {

  return (
    <Modal size="m" centered show={show} onHide={handleClose}>
      <Modal.Header className="modal-header px-5 py-4" closeButton>
        <Modal.Title className="modal-title fs-5">
          {`${modalTitle}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body position-relative px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
          <div className="upload-file-wrapper">
            <form action="#" method="get" onSubmit={handleSubmit}>
              <div className="facility-view">
                <label htmlFor="FacilityOne" className="form-label ps-5">
                  {`${modalHeading}`}
                </label>

                <div className="input-group mb-3">
                  {amenityCropImage? (
                    <div
                      className="amenity-upload-img ml-4"
                      style={{ overflow: "hidden",backgroundColor: "darkgrey", }}
                    >
                      <img
                        src={
                          amenityCropImage
                        }
                      ></img>
                    </div>
                  ) : (
                    <div className="amenity-upload-img ml-4">
                      <img
                        src={values.amenityFromEditIcon}
                        className=""
                        style={{ overflow: "hidden" }}
                      />
                    </div>
                  )}
                  <label
                    className="input-group-text px-3 facility-icon position-relative"
                    htmlFor="UploadFacilityIcon"
                  >
                    <i className="fa-solid fa-camera"></i>
                    <input
                      type="button"
                      onClick={() => showCropMediaModal("amenityIcon")}
                      name="amenityIcon"
                      className=" btn position-absolute"
                      id="UploadFacilityIcon"
                      onBlur={handleBlur}
                    />
                  </label>

                  <input
                    className="form-control py-3 shadow-none px-4"
                    name="amenityName"
                    maxLength={80}
                    value={values.amenityName}
                    onChange={handleChange}
                  />
                </div>
                {errors.amenityName && touched.amenityName ? (
                  <p className="form-error text-danger">{errors.amenityName}</p>
                ) : null}
              </div>

              <div className="pt-2 text-end mb-2">
                <button
                  onClick={(e) => handleClose(e)}
                  className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-1"
                >
                  Cancel
                </button>
                

                <input
                  disabled={aminityLoading}
                  type="submit"
                  onClick={handleSubmit}
                  value="Next"
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                />
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AmenityModal;
