import React, { useState } from "react";
import Table from "../../components/Table2";
import { getEventEnquiryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../globalContext";
import { useContext } from "react";
const EventEnquiry = () => {
    const { bwebsiteId } = useContext(GlobalContext);

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showMessages, setMessages] = useState("");

    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");

    const allEventEnqueryData = useQuery(
        ["getEventEnquiryData", +websiteIdFromCookie],
        () => getEventEnquiryData(websiteIdFromCookie)
    );

    const handleViewMessageModal = (message) => {
        setMessages(message);

        setShowMessageModal(true);
    };
    const closeMessageModal = () => setShowMessageModal(false);

    let columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },

            {
                Header: "Name",
                Cell: ({ row }) => `${row.original.name}`,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ row }) => `${row.original.email}`,
            },
            {
                Header: "Phone Number",
                accessor: "phone_no",
                Cell: ({ row }) => `${row.original.phone_no}`,
            },
            
            {
                Header: "Purpose of Event",
                accessor: "event_purpose",
                Cell: ({ row }) => `${row.original.event_purpose===null?"":row.original.event_purpose}`,
            },
            {
                Header: "Venue Type",
                accessor: "event_type",
                Cell: ({ row }) => `${row.original.event_type===null?"":row.original.event_type}`,
            },
            
            {
                Header: "Company Name",
                accessor: "company_name",
                Cell: ({ row }) => `${row.original.company_name===null?"":row.original.company_name}`,
            },
            {
                Header: "Guest Rooms",
                accessor: "guest_room",
                Cell: ({ row }) => `${row.original.guest_room===null?"":row.original.guest_room}`,
            },
            {
                Header: "Attendees",
                accessor: "attendees",
                Cell: ({ row }) => `${row.original.attendees===null?"":row.original.attendees}`,
            },
            {
                Header: "Event Start Date",
                accessor: "event_start_date",
                Cell: ({ row }) =>
                    moment(row.original.event_start_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            },
            {
                Header: "Event End Date",
                accessor: "event_end_date",
                Cell: ({ row }) =>
                    moment(row.original.event_end_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            }, 
            {
                Header: "Created on",
                accessor: "created_on",
                Cell: ({ row }) =>
                    moment(row.original.created_on).format("MMM D, YYYY h:mm"),
            },           
          
        ],
        []
    );
    
  
    if (allEventEnqueryData.status === "loading") {
        <div>Loading</div>;
    }

    if (!allEventEnqueryData.data) {
        <div>Loading</div>;
    }
    if (allEventEnqueryData.isLoading) {
        <div>Loading</div>;
    }
    const headerHeading = "All Event Enquiries";

    return (
        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-header">
                            <h4 className="fw-normal m-0">Event Enquiries</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5 mt-4">
                        {/* <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All Enquiries</h5>
                        </div> */}
                        {allEventEnqueryData.data &&
                            (allEventEnqueryData.isLoading || !allEventEnqueryData?.data ? (
                                <div className="text-center">Loading...</div>
                            ) : allEventEnqueryData?.data?.data?.length === 0 ? (
                                <div className="card-body text-center">No Data Available</div>
                            ) : (
                                <Table
                                    data={allEventEnqueryData?.data?.data}
                                    key={allEventEnqueryData?.data?.data.enquiry_id}
                                    columns={columns}
                                    isShowingHeading={true}
                                    isExcelDownloadBtnShow={false}
                                    headerHeading={headerHeading}
                                />
                            ))}
                    </div>
                </div>
            </div>

            {/* START  DIALOG MODAL */}

            <Modal show={showMessageModal} onHide={closeMessageModal}>
                <Modal.Header className="px-5 py-4" closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <p>{showMessages}</p>
                    <div className="text-end mt-4">
                        <Button
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            variant="secondary"
                            onClick={closeMessageModal}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* END  DIALOG MODAL */}
        </>
    );
};

export default EventEnquiry;
