import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import * as dataquery from "../dataService/DataService";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
export default function DeleteCollateralsHead({
  row,
  show,
  handleClose,
  rowData,
  rowNameData,
  deleteCollHeadLoading,
  setDeleteCollHeadLoading,
}) {
  const queryClient = useQueryClient();

  const handleArchive = (data) => {
    deleteCollateralRecord.mutate(data);
  };
  const deleteCollateralRecord = useMutation(dataquery.deleteCollateralsHead, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
      setDeleteCollHeadLoading(false);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllCollaterals");
      setDeleteCollHeadLoading(false);
      handleClose();
    },
    onMutate: () => {
      setDeleteCollHeadLoading(true);
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteCollHeadLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <p>
              Once you delete head,all data related to it will also be deleted.{" "}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn-danger text-uppercase"
            onClick={() => handleArchive(rowData)}
            disabled={deleteCollHeadLoading}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
