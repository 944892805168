import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import Table1 from "../../components/Table1";
import {
  getAllOffers,
  getAllResorts,
  updateOfferIsActive,
  updateOfferSorting,
} from "../dataService/DataService";
import { useMutation, useQuery } from "react-query";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import WithOutDragingTable from "../../components/Table1";
import { Table } from "../../components/dragTable/Table";
const ManageOffers = () => {
  const navigate = useNavigate();

  const handleNavigateToEdit = (offerId) => {
    navigate(`/manage-offers/edit-offer/${offerId}`);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [data, setData] = useState([]);
  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.original.website_offer_id);
    setSelectedRowNameData(rowData.original.offer_name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStatus = (e, row) => {
    const status = e.target.value;
    const id = row.original.website_offer_id;
    row.original.is_active = status;
    const data = changeApplicationStatusMutate.mutate({ id, status });
  };

  const changeApplicationStatusMutate = useMutation(updateOfferIsActive, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();
      toast.success(data.message);
    },
  });

 const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteNameFromCookie = getCookie("websiteName");
// console.log("websiteIdFromCookie",websiteNameFromCookie)


  // const columns = React.useMemo(
  //   () => [
  //     { Header: "Sr. No", accessor: "srNo" },
  //     {
  //       Header: "Offer Name",
  //       accessor: "offer_name",
  //       Cell: ({ row }) => <>{row.original.offer_name}</>,
  //     },
  //     {
  //       Header: "Discount",
  //       Cell: ({ row }) => (
  //         <>
  //           {row.original.discount_text?.length > 10
  //             ? `${row.original.discount_text.substring(0, 10)}...`
  //             : row.original.discount_text}
  //         </>
  //       ),
  //     },
  //     { Header: "Resort Name", accessor: "resort_name" },
  //     { Header: "Offer Type", accessor: "offer_type" },
  //     { Header: "Featured to", accessor: "featured_to" },
  //     // {
  //     //   Header: "Description",
  //     //   accessor: "description",
  //     //   Cell: ({ row }) => (
  //     //     <>
  //     //       {row.original.description.length > 20
  //     //         ? `${row.original.description.substring(0, 20)}...`
  //     //         : row.original.description}
  //     //     </>
  //     //   ),
  //     // },
  //     {
  //       Header: "Status",
  //       Cell: ({ row }) => (
  //         <select
  //           onChange={(e) => handleStatus(e, row)}
  //           value={row.original.is_active}
  //           className="form-select"
  //           style={{ width: "110px" }}
  //         >
  //           <option key="1" value="1">
  //             Active
  //           </option>
  //           <option key="0" value="0">
  //             Inactive
  //           </option>
  //         </select>
  //       ),
  //     },

  //     {
  //       Header: "Actions",
  //       Cell: ({ row }) => (
  //         <>
  //           <button
  //             onClick={() =>
  //               handleNavigateToEdit(row.original.website_offer_id)
  //             }
  //             className="btn btn-primary btn-sm btn-theme"
  //           >
  //             <i className="fa fa-pencil" aria-hidden="true"></i>
  //           </button>
  //           <button
  //             variant="info"
  //             onClick={() => handleShowModal(row)}
  //             className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
  //             disabled={deleteLoading}
  //           >
  //             <i className="fa fa-trash" aria-hidden="true"></i>
  //           </button>
  //         </>
  //       ),
  //     },
  //   ],
  //   []
  // );
  const columns = React.useMemo(() => {
    const commonColumns = [
      { Header: "Sr. No", accessor: "srNo" },
      {
        Header: "Offer Name",
        accessor: "offer_name",
        Cell: ({ row }) => <>{row.original.offer_name}</>,
      },
      {
        Header: "Discount",
        Cell: ({ row }) => (
          <>
            {row.original.discount_text?.length > 10
              ? `${row.original.discount_text.substring(0, 10)}...`
              : row.original.discount_text}
          </>
        ),
      },
      { Header: "Resort Name", accessor: "resort_name" },
      { Header: "Featured to", accessor: "featured_to" },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_active}
            className="form-select"
            style={{ width: "110px" }}
          >
            <option key="1" value="1">
              Active
            </option>
            <option key="0" value="0">
              Inactive
            </option>
          </select>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                handleNavigateToEdit(row.original.website_offer_id)
              }
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
              disabled={deleteLoading}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ];
  
    if (websiteNameFromCookie === "Kolkata") {
      commonColumns.splice(4, 0, { Header: "Offer Type", accessor: "offer_type" });
    }
  
    return commonColumns;
  }, []);
  let allOffers = useQuery("getAllOffers", getAllOffers);
  const allResorts = useQuery("getAllResorts", getAllResorts);

  if (!allResorts.data) {
    <div>Loading</div>;
  }
  if (allResorts.isLoading) {
    <div>Loading</div>;
  }

  if (!allOffers.data) {
    <div>Loading</div>;
  }
  if (allOffers.isLoading) {
    <div>Loading</div>;
  }
  useEffect(() => {
    if (allOffers?.data) {
      setData(allOffers?.data?.data);
    }
  }, [allOffers]);
 
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0">Manage Offers</h4>
            {/* <Link
              to="/manage-offers/create-offers"
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
            >
              Create Offer
            </Link> */}
          </div>

          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">All Offers</h5>
                  <div className="right-actions">
                    <Link
                      to="/manage-offers/create-offers"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 newuseradd-btn"
                    >
                      Create Offer
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tabledata">
                    <div className="table-responsive">
                      {/* {allOffers.data && ( */}
                        {allOffers.isLoading || !allOffers?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : data?.length === 0 ? (
                        <div className="card-body text-center">No Data Available</div>
                      ) : (
                        <>
                            <>
                              <Table
                                data={data}
                                setData={setData}
                                columns={columns}
                                isShowingHeading={false}
                                updateSortingTable={updateOfferSorting}
                                componentName={"offer"}
                                resortsData={allResorts?.data?.data}
                                
                              />
                            </>
                          {/* )} */}
                        </>
                      )}
                      <DeleteModal
                       setDeleteLoading={setDeleteLoading}
                       deleteLoading={deleteLoading}
                        show={showModal}
                        handleClose={handleCloseModal}
                        rowData={selectedRowData}
                        rowNameData={selectedRowNameData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageOffers;
