import React, { useState, useEffect } from "react";
import whiteLogoImage from "../assets/images/white-logo-circle.svg";
import whiteLogoImageAhr from "../assets/images/white-logo-circle -ahr.svg";
import SideBarItem from "./SideBarItem";
import { getSideBarData } from "../pages/dataService/DataService";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useContext } from "react";
import { GlobalContext } from "../globalContext";


const SideBar = ({ isSideBarActive, setIsSideBarActive }) => {
  const navigate = useNavigate();
  const { bwebsiteId ,navigation,setNavigation } = useContext(GlobalContext);


  const [activeItem, setActiveItem] = useState(null);
  const [userRole, setuserRole] = useState(null);

  const handleSideBarItemClick = (menuName) => {
    // setActiveItem(activeItem === menuName ? null : menuName);
    setNavigation(navigation === menuName ? null : menuName);
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };

  // Usage example
  const myCookieValue = getCookie("websiteId") ? getCookie("websiteId") : 0;
  const sidebarData = useQuery(
    ["SideBarData", myCookieValue], // Using an array as the query key with offerId
    () => getSideBarData(myCookieValue)
  );

  const location = useLocation();

  useEffect(() => {
    // Function to read a specific cookie variable
    const getCookie = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie is the one we are looking for
        if (cookie.startsWith(name + "=")) {
          // Return the value of the cookie
          return cookie.substring(name.length + 1);
        }
      }
      // Return null if the cookie is not found
      return null;
    };

    // Usage example
    const myCookieValue = getCookie("userRole");
    setuserRole(myCookieValue);
  }, []); // Empty dependency array to run the effect once on mount

  const user = getCookie("user");

  if (user == "" || user == null) {
    navigate("/");
  }

  const path = location.pathname;
  const pathArray = path.split("/");

  // Extract the values
  const firstValue = pathArray[1]; // "manage-offers"
  const secondValue = pathArray[2];

  const containsText = (item, searchText) => {
    if (
      item.navigation_url &&
      item.navigation_url.includes(searchText) &&
      item.navigation_url != null
    ) {
      return true;
    }

    if (item.subItems) {
      // Check subItems recursively
      if (item.subItems.some((subItem) => containsText(subItem, searchText)))
        return true;
    }

    return false;
  };

  // Example: Check if any navigation_url contains the text "/manage-roles"
  // const searchText = "/manage-roles";
  if (
    sidebarData &&
    sidebarData.data &&
    sidebarData.data.data &&
    !location.pathname.toLowerCase().includes("home")
  ) {
    const isTextPresent =
      sidebarData &&
      sidebarData.data &&
      sidebarData.data.data &&
      sidebarData.data.data.some((item) => containsText(item, firstValue));

    // let issecondTextPresent=false;
    // if(secondValue)
    //  issecondTextPresent = sidebarData && sidebarData.data && sidebarData.data.data && sidebarData.data.data.some((item) => containsText(item, secondValue));

    if (!isTextPresent) {
      // navigate(`/home:${myCookieValue}`);
      navigate("/websites-list");
    }
  }


  return (
    <div
      id="sidebar-wrapper"
      className={`sidebar-wrapper position-fixed top-0 start-0 h-100 ${isSideBarActive ? "close_icon" : ""
        }  `}
    > 
      <div className="logo-wrapper px-2 py-3 text-center">
        {
          bwebsiteId==="AHR" ?(
            
            <img
              src={whiteLogoImageAhr}
              alt="Atmosphere Living"
              title="Atmosphere Living"
            />

          ):(
            <img
              src={whiteLogoImage}
              alt="The Ozen Collection"
              title="The Ozen Collection"
            />

          )
        }
        {/* <p className="text-white mt-3 mb-0">Atmosphere Hotels & Resorts</p> */}
      </div>

      <nav className="sidebar-menu-list navbar-brand-box vscroll">
        <SimpleBar className="h-100" autoHide={false}>
          <ul className="list-unstyled m-0 p-0">
            {sidebarData &&
              sidebarData.data &&
              sidebarData.data.data &&
              sidebarData.data.data.map((data) => (
                <SideBarItem
                  data={data}
                  key={data.navigation_item_id}
                  isActive={navigation === data.navigation_item}
                  onSideBarItemClick={() =>
                    handleSideBarItemClick(data.navigation_item)
                  }
                />
              ))}
          </ul>
        </SimpleBar>

      </nav>
    </div>
  );
};

export default SideBar;
